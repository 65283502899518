export const Constants = Object.freeze({
    title: 'title',
    titleLevel: 'titleLevel',
    classNames: 'classNames',
    callToActionLink: 'callToActionLink',
    callToActionLabel: 'callToActionLabel',
    description: 'description',
    disclaimer: 'disclaimer',
    disclaimerReference: 'disclaimerReference',
    eyebrow: 'eyebrow',
    backgroundAsset: 'backgroundAsset',
    mobileBackgroundAsset: 'mobileBackgroundAsset',
    backgroundAssetSecondScreen: 'screen2BackgroundAsset',
    mobileBackgroundAssetSecondScreen: 'screen2MobileBackgroundAsset',
    anchorId: 'anchorId',
    hashChange: 'hashchange',
    resize: 'resize',
    close: 'close',
    play: 'play',
    mobile: 'mobile',
    desktop: 'desktop',
    chevronRight: 'chevronRight',
    blue: 'blue',
    smooth: 'smooth',
    backgroundImage: 'backgroundImage',
    mainAsset: 'mainAsset',
    name: 'name',
    empty: '',
    blocks: 'blocks',
    body: 'body',
    submit: 'submit',
    json: 'application/json',
    context:'http://schema.org',
    typeLD:'application/ld+json',
    product:'Product',
    whiteText: 'white-text',
    white: 'white',
    content: 'content ',
    top: 'top',
    primaryColor: '#0057B8',
    scroll: 'scroll',
    ratingSummary: 'rating_summary',
    button: 'button',
    chevronDown: 'chevronDown',
    chevronTop: 'chevronTop',
    wideChevronDown: 'wideChevronDown',
    thinChevronDown: 'thinChevronDown',
    itemType: 'https://schema.org/Product',
    text: 'text',
    small: 'small',
    medium: 'medium',
    large: 'large',
    h1: 'h1',
    h2: 'h2',
    keydown: 'keydown',
    Tab: 'Tab',
    Escape: 'Escape',
    touchmove: 'touchmove',
    mediumLong: 'medium-long',
    alreadyInUse: 'already in use',
    brand: 'OralB',
    pathDelimiter: '/',
    collectionHeroSection: 'collection-hero-section',
    collectionHeroSectionCta: 'collection-hero-section-cta',
    titleLinkName: 'title-link',
    kidsProductCategory: 'Kids Product Category',
    whyYouShouldWin: 'why you should win',
    brushType: 'brush type',
    optins: 'optIns',
    optinIdx: 0,
    autoComplete:'on',
    aboutReviews: 'about reviews',
    tabWidthStart: 768,
    desktopViewStart: 1022,
    ESCAPE_KEY_CODE: 27,
    Answer:'Answer',
    Question:'Question',
    HowToSchema: 'Howto',
    Loading: 'Loading',
    breadcrumb: 'BreadcrumbList',
    ListItem:'ListItem',
    article: 'BlogPosting',
    WebPage:'WebPage',
    FAQschema:'FAQPage',
    ImageObject:'ImageObject',
    organization: 'Organization',
    signInEmail: 'signInEmail',
    signInPassword: 'signInPassword',
    emailVariant: 'email variant',
    traitTrafficSourceIdValue:'traitTrafficSourceIdValue',
    HowToStep: 'HowToStep',
    question: 'question',
    answer: 'answer',
    PrivacyLinkPlaceholder: '{{privacyLink}}',
    answer: 'answer',
    bannerImagePositionLeft: 'Left',
    bannerImagePositionUp: 'Up',
    bannerImagePositionRight: 'Right',
    black: 'black'
});

export const PrePurchaseConstants = Object.freeze({
    jebbitData: 'jebbitData',
    infoIcon: 'info icon',
    minus6: 'minus6',
    plus: 'plus',
    blue: Constants.blue,
    modes: ' modes',
    accessories: ' accessories',
    button: Constants.button,
    MAX_COL_DESKTOP: 3,
    MAX_COL_MOBILE: 2,
    customerReviwew: 'customer-review',
    buyNowCta: 'buyNowCta',
    master: 'master_',
    ratingSummary: Constants.ratingSummary,
    inlineRating: 'inline_rating',
    row: 'row',
    table: 'table',
    rowheader: 'rowheader',
    columnheader: 'columnheader',
    cell: 'cell',
    itemType: Constants.itemType,
    ProductName: 'ProductName',
    altTextPrefixFeatureImage: 'compare-chart-feature',
    noSlug: '#',
    QUERY_PARAM_PRODUCT1: 'product1',
    QUERY_PARAM_PRODUCT2: 'product2',
    QUERY_PARAM_PRODUCT3: 'product3',
    writeAReviewQueryParam: 'writeReview',
    writeAReviewQueryParamValue: 'true',
    WAR_CTA_ID: 'WAR',
    WAR_CTA_CLICK_TIMEOUT: 1000,
    sectionId:'pre-purchase-result',
    CENTER_ALGN_TIMEOUT: 800,
    WAR_REVIEW_LALBEL_UPDATE_TIMEOUT: 10000,
    compareResultSectionMobileViewClass: 'pre-purchase-comparison-mobile-view',
    scrollYOffset: 120,
    reviewDetailContainerClass: 'pre-purchase-review-detail',
    dataActionDetail : 'MeetYourBrush_LearnMore_BestMatch',
    typeOnchange: 'typeOnChange',
    typeOnBlur: 'typeOnBlur',
    ariaLabel:'select your brush to compare'
 });

export const PostPurchaseConstants = Object.freeze({
    promoCode: '<PROMOCODE>',
    campaignPath: '/campaign',
    registerPath: '/registration',
    voucherCountPath: '/voucherCount',
    voucherIdKey: 'voucherIdKey',
    noSlug: '#',
    dropBtnClass: '.post-purchase-bv-widget__dropbtn',
    dropdownContant: 'post-purchase-bv-widget__dropdown-content',
    dropdownClass: 'post-purchase-bv-widget__display-dropdown',
    close: Constants.close,
    WAR_CTA_ID: PrePurchaseConstants.WAR_CTA_ID,
    WAR_POP_ID: 'bv_war_button',
 });

export const OrganizationConstants = Object.freeze({
   brand: 'OralB',
   parentOrganization: 'Procter & Gamble'
});

export const AdditionalReadingBlockConstants = Object.freeze({
    additionalReadingBlock: 'additionalReadingBlock',
    articleLink: 'article link'
});

export const CampaignIframeOverlayConstants = Object.freeze({
    userDataCollectedIntersistialPopUp: 'userDataCollectedIntersistialPopUp',
    disableIntersistialPopUpCookie: 'disableIntersistialPopUp',
    isEnableCampaignOverlay: 'isEnableCampaignOverlay',
    email:'Email',
    interestitialUserDetail: 'interestitialUserDetail',
    expiryHours: 'expiryHours',
    labelFirstStep: 'labelFirstStep',
    labelSecondStep: 'labelSecondStep',
    screen2SubmitCtaText: 'screen2SubmitCtaText',
    formLabelFirstStep: 2,
    interstitial_emailSubmit: 'Interstitial_Toothbrush',
    interstitial_openquestion: 'Interstitial_WinterMemory',
    startSurveyEvent: 'event_survey_start',
    closeSurveyEvent: 'event_survey_close',
    completeSurveyEvent: 'event_survey_complete',
    customEvent: 'customEvent',
    eventCategory: 'event_survey_action',
    whyYouShouldWin:'whyYouShouldWin',
    disableIntersistialPopUpCookieApp: 'disableIntersistialPopUpApp',
    eventLabel: 'interstitial_view',
    eventCloseCategory: 'event_informational_action',
    eventCloseAction: 'event_close_window',
    eventCloseLabelWithoutNoResponse: 'interstitial_close_no_response',
    eventCloseLabelWithSurveyResponse: 'interstitial_close_with_survey_response',
    eventRegisterLabel: 'interstitial_email_response',
    questionSurveyLabel: 'interstitial_question_view',
    questionSurveyAction: 'event_survey_question'
});

export const AnnouncementBarConstants = Object.freeze({
    disableDays: 'disableDays',
    AnnouncementBarDisableDate: 'AnnouncementBarDisableDate',
    closeAriaLabel: 'closeAriaLabel',
    close: Constants.close,
    classNames: Constants.classNames,
    callToActionLink: Constants.callToActionLink,
    callToActionLabel: Constants.callToActionLabel,
    description: Constants.description,
    ctaName: 'electric toothburshes',
});

export const AppDownloadBlockConstants = Object.freeze({
    title: Constants.title,
    classNames: Constants.classNames,
    eyebrow: Constants.eyebrow,
    backgroundAsset: Constants.backgroundAsset,
    mobileBackgroundAsset: Constants.mobileBackgroundAsset,
    downloadLinks: 'downloadLinks',
    mobile: Constants.mobile,
    desktop: Constants.desktop,
    body: Constants.body,
    button: 'BUTTON'
});

export const ArticleCategoryHeroBlockConstants = Object.freeze({
    title: Constants.title,
    titleLevel: Constants.titleLevel,
    classNames: Constants.classNames,
    eyebrow: Constants.eyebrow,
    body: Constants.body,
    backgroundAsset: Constants.backgroundAsset,
    mobileBackgroundAsset: Constants.mobileBackgroundAsset,
    ctas: 'ctas',
    chevronRight: Constants.chevronRight,
    disclaimer: Constants.disclaimer,
    blue: Constants.blue,
    mainAsset: 'mainAsset',
    mobileAsset: 'mobileAsset',
    textToTheRight: 'textToTheRight',
    backgroundColor: 'backgroundColor',
    callToActionAriaLabel: 'callToActionAriaLabel'
});

export const ArticleHeroBlockConstants = Object.freeze({
    classNames: Constants.classNames,
    readTimeLabel: 'readTimeLabel',
    articleBanner: 'article banner',
    lazyIndexArticle: 4
});


export const ImageConstants = Object.freeze({
    defaultImageWindWidth: 450,
    defaultWidth: 400,
    defaultDesktopWidth:768,
    dpr_1440: 'f_auto,q_auto,w_1440',
    product: 'product',
    mobile_max_width: '767px',
    desktop_min_width: '768px'
});

export const ArticlePopUpConstants = Object.freeze({
    title: Constants.title,
    body: 'body',
    facebookLabel: 'facebookLabel',
    twitterLabel: 'twitterLabel',
    closeOverlayLabel: 'closeOverlayLabel'
});

export const BrushConfiguratorConstants = Object.freeze({
    dataName: 'data-name',
    smooth: Constants.smooth,
    center: 'center',
    switch: 'switch',
    status: 'status',
    features: 'features',
    live: 'live',
    off: 'off'
});

 export const ButtonTabsNavigationConstant = Object.freeze({
   replacementBrushHead: 'Replacement Brush Head',
   chevronDown: 'chevronDown',
   panel: 'panel',
   tab: 'tab',
   tablist: 'tablist',
   chevronLeft: 'chevronLeft',
   chevronRight: Constants.chevronRight,
 });

 export const FAQConstants = Object.freeze({
   link: 'link'
 });

 export const IconsConstant =  Object.freeze({
   role: 'img',
   iconOne: 'icon-1',
   iconTwo: 'icon-2',
   obIconRoundedContainer: 'ob-icon-rounded-container'
 });

 export const labelConstant =  Object.freeze({
    reviewLabel: 'Search topics and reviews'
  });

export const CharacterBlockConstants = Object.freeze({
    title: Constants.title,
    titleLevel: Constants.titleLevel,
    classNames: Constants.classNames,
    eyebrow: Constants.eyebrow,
    description: Constants.description,
    backgroundImage: Constants.backgroundImage,
    mobileBackgroundImage: 'mobileBackgroundImage',
    characterLogo: 'characterLogo',
    mobileCharacterLogo: 'mobileCharacterLogo',
    maxWidth: '(max-width: 667px)',
    anchorId: Constants.anchorId,
});

export const CollectionTabsBlockConstants = Object.freeze({
    smooth: Constants.smooth,
    hash: '#',
    noDropDown: 'No Drop Down',
});

export const ComparisonChartBlockConstants = Object.freeze({
    row: 'row',
    table: 'table',
    rowheader: 'rowheader',
    columnheader: 'columnheader',
    cell: 'cell',
    noValueLabel: 'noValueLabel',
    top: Constants.top,
    chevronRight: Constants.chevronRight,
    jumpToConfiguratorLink: 'jumpToConfiguratorLink',
    altTextPrefixFeatureImage: 'comparison-chart-feature',
    ariaLabel: 'Select first IO series'
});

export const ContactUsBlockConstants = Object.freeze({
    classNames: Constants.classNames,
    contactUs: 'contact-us'
});

export const ContentConstants = Object.freeze({
    playing: 'playing',
    play: 'play',
    pause: 'pause',
    pauseLabel: 'pauseLabel',
    playLabel: 'playLabel',
    resetLabel: 'resetLabel',
    openTranscriptLabel: 'openTranscriptLabel',
    closeTranscriptLabel: 'closeTranscriptLabel',
    ended: 'ended',
    Src: 'Src',
    Dest: 'Dest',
    x: 'x',
    y: 'y',
    White: 'White',
    white: Constants.white,
    blue: Constants.blue,
    transparentWithBorder: 'Transparent With Border',
    blueBorderBtn: 'ob-blue-border-btn',
    chevronRight: Constants.chevronRight,
    primaryGrey: 'primaryGrey',
    callToActionLink: Constants.callToActionLink,
    callToActionLabel: Constants.callToActionLabel,
    surtitle: 'surtitle',
    description: Constants.description,
    classNames: Constants.classNames,
    callToActionIsALink: 'callToActionIsALink',
    isLinkInNewTab: 'isLinkInNewTab',
    title: Constants.title,
    titleLevel: Constants.titleLevel,
    backgroundColor: 'backgroundColor',
    textContainerBackgroundColor: 'textContainerBackgroundColor',
    textContainerBackgroundOpacity: 'textContainerBackgroundOpacity',
    textContainerHasPadding: 'textContainerHasPadding',
    blueEyebrow: 'blueEyebrow',
    disclaimer: Constants.disclaimer,
    disclaimerReference: Constants.disclaimerReference,
    textsImage: 'textsImage',
    cloudinaryVideo: 'cloudinaryVideo',
    devicesConfigurations: 'devicesConfigurations',
    deviceConfigurations: 'deviceConfigurations',
    isMedium: 'isMedium',
    blockMinHeight: 'blockMinHeight',
    blockMarginTop: 'blockMarginTop',
    blockMarginBottom: 'blockMarginBottom',
    blockMarginRight: 'blockMarginRight',
    blockMarginLeft: 'blockMarginLeft',
    blockPadding: 'blockPadding',
    textAlign: 'textAlign',
    textContainerWidth: 'textContainerWidth',
    titleWidth: 'titleWidth',
    descriptionWidth: 'descriptionWidth',
    textContainerHorizontalAlignment: 'textContainerHorizontalAlignment',
    textContainerVerticalAlignment: 'textContainerVerticalAlignment',
    textContainerOffset: 'textContainerOffset',
    textContainerMarginRight: 'textContainerMarginRight',
    textContainerMarginLeft: 'textContainerMarginLeft',
    textContainerMarginBottom: 'textContainerMarginBottom',
    mainAsset: 'mainAsset',
    mainAssetBelowOrAboveText: 'mainAssetBelowOrAboveText',
    mainAssetMaxWidth: 'mainAssetMaxWidth',
    mainAssetMaxHeight: 'mainAssetMaxHeight',
    mainAssetHeight: 'mainAssetHeight',
    mainAssetMarginTop: 'mainAssetMarginTop',
    mainAssetMarginRight: 'mainAssetMarginRight',
    mainAssetOffsetBottom: 'mainAssetOffsetBottom',
    mainAssetOffsetLeft: 'mainAssetOffsetLeft',
    mainAssetScale: 'mainAssetScale',
    backgroundAsset: 'backgroundAsset',
    mainAssetHorizontalAlignment: 'mainAssetHorizontalAlignment',
    mainAssetVerticalAlignment: 'mainAssetVerticalAlignment',
    backgroundPosition: 'backgroundPosition',
    sectionOffsetTop: 'sectionOffsetTop',
    mainAssetParallax: 'mainAssetParallax',
    mainAssetParallaxSetting: 'mainAssetParallaxSetting',
    textParallax: 'textParallax',
    textParallaxSetting: 'textParallaxSetting',
    textParallaxConfiguration: 'textParallaxConfiguration',
    assetParallaxConfiguration: 'assetParallaxConfiguration',
    textShowAnimation: 'textShowAnimation',
    textFadeIn: 'textFadeIn',
    textColor: 'textColor',
    respectImageAspectRatio: 'respectImageAspectRatio',
    titleFontSize: 'titleFontSize',
    small: Constants.small,
    medium: Constants.medium,
    large: Constants.large,
    ctaBackgroundColor: 'ctaBackgroundColor',
    aboveText: 'above text',
    belowText: 'below text',
    hasDivider: 'hasDivider',
    isRemoveParallax:'isRemoveParallax',
    defaultWidth: 350,
    defaultWindWidth: 400,
    CtalabelId:'ctaLabelId',
    ctaName: 'collection-cta',
    reset: 'reset',
    contentOptions: 'contentOptions',
    contentOptionClassName: 'contentOptionClassName',
    googlePlayIcon: 'googlePlayIcon',
    appStoreIcon: 'appStoreIcon',
    noteText: 'noteText',
    textAlignBottomMobileView: 'text-align-bottom-mobile-view',
    mobileWidth: 767,
    reviewModule: 'reviewModule',
    dataActionDetailLabel:'dataActionDetailLabel',
    eventClassName:'eventClassName',
    replay: 'replay',
    descriptionBelowOption: 'descriptionBelowOption',
    callToActionType: 'callToActionType',
    CtalabelId:'ctaLabelId',
    subDescription: 'subDescription',
    mobileMainHeading: 'mobileMainHeading',
    cards: 'cards',
    titleImage: 'titleImage',
    scrollContent: 'scrollContent',
    animationAsset: 'animationAsset',
    additionalAssetList: 'additionalAssetList',
});

export const ContentVideoConstants = Object.freeze({
    Tab: Constants.Tab,
    keydown: Constants.keydown,
    description: Constants.description,
    disclaimer: Constants.disclaimer,
    mobileLayout: 'mobileLayout',
    desktopLayout: 'desktopLayout',
    whiteText: Constants.whiteText,
    backgroundImage: 'background-image',
    title: Constants.title,
    titleLevel: Constants.titleLevel,
    classNames: Constants.classNames,
    textContainerMarginBottom: 'textContainerMarginBottom',
    textContainerMarginTop: 'textContainerMarginTop',
    playIconColor: 'playIconColor',
    backgroundColor: 'backgroundColor',
    playLabel: 'playLabel',
    closeLabel: 'closeLabel',
    close: Constants.close,
    play: Constants.play,
    pauseLabel: 'pauseLabel',
    resetLabel: 'resetLabel',
    openTranscriptLabel: 'openTranscriptLabel',
    closeTranscriptLabel: 'closeTranscriptLabel',
    white: Constants.white,
    whiteHexThreeChars: '#fff',
    whiteHexFull: '#ffffff',
    surtitle: 'surtitle',
    blueEyebrow: 'blueEyebrow',
    contentOptions: 'contentOptions',
    playIcon: 'playIcon',
    textImage: 'textImage',
    youtubeVideoBlock: 'youtubeVideoBlock',
    mainHeading: 'mainHeading',
});

export const AwardsBlockConstant = Object.freeze({
    awardItemLogo: 'award-item-logo'
});

export const FAQBlockConstants = Object.freeze({
    switch: 'switch',
    button: Constants.button,
    minus6: 'minus6',
    plus: 'plus',
    arrowRight: 'ovalArrowRight',
    popularSentence: 'Popular topics others look for',
    searchResultsTitle: 'We found 6 results for Warranty',
    obSupport: 'Oral-B Support',
    Previous: 'Previous',
    Next: 'Next',
    of: 'of',
    arrowDown: 'chevronDown'
});

export const FooterConstants = Object.freeze({
    resize: Constants.resize,
    copyright: 'copyright',
    copyrightAt: '&copy;',
    countryCode: 'SE',
    lang: 'sv',
    privacyLink: 'privacyLink',
});

export const GalleryBlockConstants = Object.freeze({
    cloudinaryImage: 'cloudinaryImage',
    classNames: Constants.classNames,
    title: Constants.title,
    whiteText: Constants.whiteText,
    anchorId: Constants.anchorId,
    seeMoreButtonLabel: 'seeMoreButtonLabel',
    seeLessButtonLabel: 'seeLessButtonLabel',
    minus6: 'minus6',
    blue: Constants.blue,
    plus: 'plus',
    full: 'Full',
    large: 'Large',
    small: 'Small'
});

export const InTheBoxBlockConstants = Object.freeze({
    whiteText: Constants.whiteText,
    body: 'body',
    classNames: 'classNames',
    items: 'items',
    anchorId: Constants.anchorId,
    inTheBoxImage: 'inTheBoxImage',
    title: Constants.title,
    titleLevel: Constants.titleLevel,
});

export const LanguageSelectionConstants = Object.freeze({
    primaryColor: Constants.primaryColor,
    smooth: Constants.smooth,
    keydown: Constants.keydown,
    title: Constants.title,
    regions: 'regions',
    currentLanguage: 'currentLanguage',
    jumpToLabel: 'jumpToLabel',
    closeLanguageSelectionLabel: 'closeLanguageSelectionLabel',
    wideChevronDown: 'wideChevronDown',
    close: Constants.close,
    countryLanguageSelection: 'Country and Language selection',
    countryLanguageAriaLabel:'languageSelectionAriaLabel'
});

export const LayoutConstants = Object.freeze({
    classNames: Constants.classNames,
    zoneContestCode: 'zone-contest',
    zoneThankYouCode: 'zone-thankyou',
    paddingValue:'66px',
    paddingValueBreadcrumb: '112px'
});

export const LegacyProductHighlightsConstants = Object.freeze({
    isActive: 'is-active',
    empty: Constants.empty,
    minus6: 'minus6',
    blue: Constants.blue,
    button: Constants.button,
    plus: 'plus',
    seeLess: 'SEE LESS',
    seeMore: 'SEE MORE',
    highlights: ' highlights',
    plusSymbol: '+',
    minusSymbol: '-',
    arrowUp: 'ArrowUp',
    arrowDown: 'ArrowDown',
    text: Constants.text,
    prev: 'prev',
    next: 'next',
    smooth: 'smooth',
    nearest: 'nearest',
    center: 'center',
    highlights: 'highlights',
    productRating: 'rating'
});

export const MainMenuConstants = Object.freeze({
    primaryColor: Constants.primaryColor,
    mousedown: 'mousedown',
    blur: 'blur',
    resize: Constants.resize,
    scroll: Constants.scroll,
    keydown: Constants.keydown,
    close: Constants.close,
    Open: 'Open',
    Close: 'Close',
    child: 'child',
    productByNeed: 'product-by-need',
    productByType: 'product-by-type',
    whyOralB: 'why-oral-b',
    conditions: 'conditions',
    lifeStages: 'life-stages',
    ourAmbition: 'our-ambition',
    wideChevronDown: 'wideChevronDown',
    chevronDown: Constants.chevronDown,
    plus: 'plus',
    minus6: 'minus6',
    mainMenuLabel: 'mainMenuLabel',
    hierarchy: 'hierarchy',
    classNames: Constants.classNames,
    hamburger: 'hamburger',
    closeMenuLabel: 'closeMenuLabel',
    openMenuLabel: 'openMenuLabel',
    searchLabel: 'searchLabel',
    returnToMainMenuLabel: 'returnToMainMenuLabel',
    arrowLeft: 'arrowLeft',
    searchDataActionDetail: 'Open Search Panel',
    panel: 'panel',
    pullDown: 'pull-down',
    collapsed: 'collapsed',
    expanded: 'expanded',
    expanding: 'expanding',
    escape: 'Escape',
    menubar: 'menubar',
    menuitem: 'menuitem',
    menulist: 'menu-list',
    menulistDesktop: 'menu-list-desktop',
    languageLink: 'language link',
    languageTabMobile: 'language tab mobile',
    expandingMenu: 'expanding-menu',
    closeButton: 'close button',
    menu: 'menu',
    chevronTop: 'chevronTop',
    white: 'white',
    capExpanded: 'EXPANDED',
    capExpanding: 'EXPANDING',
    capEscape: 'ESCAPE',
    screenwidth: 700,
    mobileTabLayout: 'mobileTabLayout',
    downArrow: 'down-arrow',
    upArrow: 'up-arrow',
    plusArrow: 'plus-arrow',
    minusArrow: 'minus-arrow',
    hierarchyMainMenu: 'Hierarchy---Main-Menu',
    displayNone: 'none',
    displayBlock: 'block'
});

export const MarkdownTextConstants = Object.freeze({
    script: 'script',
    text: 'text'
})

export const MetaConstants = Object.freeze({
    https: 'https://'
});

export const MiniNavigationConstants = Object.freeze({
    hierarchy: 'hierarchy',
    classNames: Constants.classNames,
    backgroundGrey: 'backgroundGrey',
    href: 'href',
    smooth: Constants.smooth,
    chevronDown: Constants.chevronDown,
    resize: Constants.resize,
    target: 'target',
    downArrow: 'downArrow'
})

export const ComponentFactoryConstants = Object.freeze({
    Zone: 'Zone',
    Layout: 'Layout',
    Content: 'ContentBlock',
    ContentVideo: 'contentVideo',
    RichText: 'richTextBlock',
    RichTextLink: 'link',
    MarkdownText: 'markdownTextBlock',
    MainMenu: 'mainMenuBlock',
    Footer: 'footerBlock',
    LanguageSelection: 'languageSelectionBlock',
    PageNavigation: 'pageNavigationBlock',
    TabbedContent: 'tabbedContentBlock',
    AnnouncementBar: 'announcementBarBlock',
    SideBySideContainer: 'sideBySideContainerBlock',
    SeriesSelector: 'seriesSelectorBlock',
    MiniNavigation: 'miniNavigationBlock',
    PromoBlock: 'promoBlock',
    SpotlightSlider: 'spotlightSlider',
    SpotlightContentVideo: 'spotlightContentVideo',
    SpotlightContentBlock: 'spotlightContentBlock',
    SpotlightStickyBar: 'spotlightStickyBarBlock',
    InTheBoxBlock: 'inTheBoxBlock',
    ProductGridBlock: 'productGridBlock',
    CollectionTabsBlock: 'collectionTabsBlock',
    ProductHighlights: 'productHighlightBlock',
    FeaturesTabBlock: 'featuresTabBlock',
    BrandGuaranteeBlock: 'brandGuaranteeBlock',
    GalleryBlock: 'galleryBlock',
    FAQBlock: 'faqBlock',
    BrushConfigurator: 'brushConfiguratorBlock',
    ComparisonChartBlock: 'comparisonChartBlock',
    UserQuoteBlock: 'userQuoteBlock',
    SpotlightWaitlistBlock: 'spotlightWaitlistBlock',
    SpotlightPreorderBlock: 'spotlightPreOrderBlock',
    CollectionTabsDropdown: 'collectionSegmentTabsDropdownBlock',
    ProductSubNav: 'productSubNav',
    CharacterBlock: 'characterBlock',
    ProductRecommenderBlock: 'productRecommenderBlock',
    ShowcaseBlock: 'showcaseBlock',
    ArticleHeroBlock: 'articleHeroBlock',
    SkipNavigationBlock: 'skipNavigationBlock',
    AppDownloadBlock: 'appDownloadBlock',
    ArticleCategoryHeroBlock: 'articleCategoryHeroBlock',
    RecentlyViewedProductsBlock: 'recentlyViewedProductsBlock',
    AdditionalReadingBlock: 'additionalReadingBlock',
    ProductOverlay: 'productOverlay',
    ErrorBlock: 'errorBlock',
    ArticleBody: 'articleBody',
    CloudinaryImage: 'cloudinaryImage',
    YoutubeVideo: 'youtubeVideoBlock',
    ResponsiveImage: 'responsiveImageBlock',
    ContactUsBlock: 'contacUsBlock',
    LoginBlock: 'loginBlock',
    ForgotPasswordBlock: 'forgotPasswordBlock',
    ResetPasswordBlock: 'resetPasswordBlock',
    CreateAccountBlock: 'createAccountBlock',
    RegisterTypeCodeBlock: 'blockRegisterTypeCode',
    PypestreamChat: 'pypestreamChat',
    SearchBlock: 'search',
    PressQuoteBlock: 'pressQuoteBlock',
    RegisterProductBlock: 'registerProductBlock',
    CongratulationsBlock: 'congratulationBlock',
    ProfileBlock: 'profileBlock',
    ChangePasswordBlock: 'changePasswordBlock',
    Awards: 'awardsBlock',
    LoginNavigationBlock: 'loginNavigationBlock',
    DesignedByDentist: 'designedByDentistBlock',
    LegacyArticleHeroBlock: 'legacyArticleHeroBlock',
    ContentDetailsBlock: 'contentDetailsBlock',
    ConsentBlock: 'ConsentBlock',
    ThreeColumnsUserQuoteBlock: 'threeColumnsQuoteBlock',
    ArticlePopUp: 'articlePopUp',
    ArticleShare: 'articleShareBlock',
    LegacyProductHighlightBlock: 'legacyProductHighlightBlock',
    ArticleListBlock: 'articleListBlock',
    ArticleTableBlock: 'articleTableBlock',
    SpotlightContestBlock: 'spotlightContestBlock',
    SpotlightContestThankYouBlock: 'spotlightContestThankYouBlock',
    ProductLegacyBody: 'productLegacyBody',
    FaqDetailsBlock: 'faqDetailsBlock',
    Disclaimer: 'disclaimer',
    ProductVariantsRecommanderBlock: 'productVariantsRecommanderBlock',
    SitemapBlock: 'sitemapBlock',
    SitemapPage: 'SitemapPage',
    HTMLBlock: 'htmlBlock',
    SpotlightExperienceBlock: 'spotlightExperienceBlock',
    DoubleOptInVerificationBlock: 'doubleOptInVerificationBlock',
    articleLink: 'article link',
    blue: 'blue',
    chevronRight: 'chevronRight',
    mediaUpper: '(min-width: 1200px)',
    mediaLower: '(min-width: 426px)',
    dprUpper: 'q=100&f=center&fit=fill&w=460&h=260',
    dprMedium: 'q=100&f=center&fit=fill&w=272&h=150',
    dprLower: 'q=100&f=center&fit=fill&w=124&h=150',
    dprAuto: 'q=auto&f=center&fit=fill&w=124&h=150',
    PostPurchaseRegisterForm: 'blockPostPurchase',
    PrePurchase: 'qrCodePrePurchase',
    PrePurchaseResult: 'qrCodePrePurchaseResults',
    PostPurchaseWriteReviewSection: 'blockPostPurchaseWriteReviewSection',
    BreadCrumbs: 'breadCrumbs',
    CarouselBlock: 'blockCarouselBlock',
    AccordionBlock: 'accordionBlock',
    SwitchTab: 'switchTabs',
    DragSlider: 'dragSlider',
    productRecommendBlockABtest:'productRecommendBlockABtest',
    MiniContentBlock: 'blockMiniContentBlock',
    ExpandableHierarchy: 'blockExpandableHierarchy',
    blockInterstitialSignupOverlay: 'blockInterstitialSignupOverlay',
    blockInterstitialRedesign: 'blockInterstitialRedesign',
    DentistContacts: 'dentistContacts',
    blockStartGuide: 'blockStartGuide',
    blockStartGuideContentBlock: 'blockStartGuideContentBlock',
    pageProgressBar: 'pageProgressBar',
    blockStartGuideThreeStepBlock: 'blockStartGuideShuffle',
    StartGuideSignupForm: 'StartGuideSignupForm',
    MiniContentBlock: 'blockMiniContentBlock',
    SwitchTab: 'switchTabs',
    ArticleCarouselBlock: 'articleCarouselBlock'
});

export const ZoneConstants = Object.freeze({
    zone: 'zone ',
    classNames: Constants.classNames,
    role: 'role',
    ariaLabel: 'ariaLabel',
    backgroundImage: Constants.backgroundImage,
    id: 'id',
    zoneSpotlightSliderBodyClassname: 'spotlight-body-zone',
    zoneSpotlightContestClassname: 'spotlight-zone-contest',
});

export const YoutubeVideoConstants = Object.freeze({
    percent: '%',
    title: 'Youtube',
    url: 'https://www.youtube-nocookie.com/embed/',
});

export const VideoPlayerConstants = Object.freeze({
    id: 'video-player',
    videoRecommendations: 'Video Recommendations overlay item information',
    videoRatioVolume: 0.3,
    descriptionButtonLowOpacity : 0.5,
    descriptionButtonHighOpacity : 1,
    descriptionButtonHighOpacity : 1,
    domainHttp : 'https:',
    audioDescriptionClassName : 'vjs-icon-audio-description',
    firstAudioFileId: 'firstAudiofile',
    secondAudioFileId: 'secondAudiofile',
    firstAudio: 'firstAudio',
    secondAudio: 'secondAudio',
});

export const UserQuoteConstants = Object.freeze({
    classNames: Constants.classNames,
    title: Constants.title,
    mainAsset: Constants.mainAsset,
    name: Constants.name,
    disclaimer: Constants.disclaimer,
    authorName: 'authorName',
    quote: 'quote',
    starRating: 'starRating',
    writeAReviewLabel: 'writeAReviewLabel',
    ratingSummary: Constants.ratingSummary,
    reviews: 'reviews',
    questions: 'questions',
    h1: Constants.h1,
    h2: Constants.h2,
    itemType: Constants.itemType,
    itemProp: Constants.name,
    displayUserReviews: 'displayUserReviews',
    reviewSection:'Write a review ',
    containerElement:'div'
});

export const UnderlineTabsNavigationConstants = Object.freeze({
    resize: Constants.resize,
    childLabel: 'Child Product Category',
    tabList: 'tablist',
    tab: 'tab'
});

export const TabbedContentConstants = Object.freeze({
    empty: Constants.empty,
    anchorName: 'anchorName',
    chevronDown: Constants.chevronDown,
    tab: 'tab-',
    blocks: Constants.blocks
});

export const StarRatingConstants = Object.freeze({
    star: 'star',
    starEmpty: 'starEmpty',
    starHalf: 'starHalf',
});

export const SpotlightWaitlistConstants = Object.freeze({
    error: 'An error occured',
    failedToRegister: ': Registration failed',
    title: Constants.title,
    body: Constants.body,
    callToActionLabel: 'callToActionLabel',
    legalText: 'legalText',
    emailDisclaimer: 'emailDisclaimer',
    emailDisclaimerTop: 'emailDisclaimerTop',
    emailDisclaimerBottom: 'emailDisclaimerBottom',
    surveyTitle: 'surveyTitle',
    surveyDescription: 'surveyDescription',
    dataActionDetail: 'dataActionDetail',
    emailLimitExceedLabel: 'emailLimitExceedLabel',
    invalidLocaleLabel: 'invalidLocaleLabel',
    registerFailedError: 'registerFailedError',
    serviceUnavailableLabel: 'serviceUnavailableLabel',
    formLabels: 'formLabels',
    classNames: Constants.classNames,
    anchorId: Constants.anchorId,
    backgroundAsset: Constants.backgroundAsset,
    mobileBackgroundAsset: Constants.mobileBackgroundAsset,
    backgroundAssetScrondScreen: Constants.backgroundAssetSecondScreen,
    mobileBackgroundAssetSecondScreen: Constants.mobileBackgroundAssetSecondScreen,
    thankYouTitleLabel: 'thankYouTitleLabel',
    thankYouBackgroundAsset: 'thankYouBackgroundAsset',
    thankYouDescriptionLabel: 'thankYouDescriptionLabel',
    thankYouCallToAction: 'thankYouCallToAction',
    thankYouCallToActionLink: 'thankYouCallToActionLink',
    thankYouCallToActionIconName: 'thankYouCallToActionIconName',
    closeModalLabel: 'closeModalLabel',
    numberOfErrorsLabel: 'numberOfErrorsLabel',
    submit: Constants.submit,
    close: Constants.close,
    gcsCampaignParam: 'campaignId',
    gcsLocaleParam: 'locale',
    contentType: 'Content-Type',
    json: Constants.json,
    accessToken: 'access_token',
    system: 'GCS',
    chevronDown: Constants.chevronDown,
    countrySelectLabel: 'countrySelectLabel',
    countrySelectList: 'countrySelectList',
    countrySelectListDefaultOption: 'countrySelectListDefaultOption',
    countrySelectErrorMessage: 'countrySelectErrorMessage',
    countrySelectMention: 'countrySelectMention',
    modalLabelFirst: 'modalLabelFirst',
    modalLabelSecond: 'modalLabelSecond',
    addressTypeCode: 'addressTypeCode',
    alternateThankYouTitleLabel: 'alternateThankYouTitleLabel',
    alternateThankYouDescriptionLabel: 'alternateThankYouDescriptionLabel',
    callToPreOrderActionLabel: 'callToPreOrderActionLabel',
    callToPreOrderActionLink: 'callToPreOrderActionLink',
    implicitTCAgree: 'implicitTCAgree',
    isInterstitialPopup: 'isInterstitialPopup',
    topicList: 'topicList',
    backgroundColor: 'backgroundColor',
    interestitialSecondModalZIndex: 999,
    interestitialModalMaxHeight: '90vh',
    interestitialUserInterestModalMaxHeight: '520px',
    interestitialThankyouModalHeight: '250px',
    closeBtnColor: '#E7EBEE',
    isiO10camapign:'isiO10camapign',
    TermConditions:'Term&Conditions',
    email:'Email',   
    fromSource:'iO10 Spotlight Campaign',
    topicTitle:'labelFirstStep',   
    iO10_Waitlist_signup_intent:'iO10_Waitlist_signup_intent_stickyNav',
    event_profile_register:'event_profile_register',
    iO10_Waitlist_signup_intent_profile:'iO10_Waitlist_signup_intent',
    anchorIdSpotlight:'spotlight-io-10',
    traitQuestionGCS:'Let us know What toothbrush do you currently use most often and receive exclusive offers and tooth care tips',
    contestQuestion:'Why do you want to win?',
    otherProps:'otherProps',
    traitValue:'SE website interstitial - Email Capture- GCS - Mindtree',
    defaultVariant:'A',
    defaultValue:'default',
    interstitalSource: 'SE website interstitial - Mixture - Mindtree',
    traitBrushTechnologyIdValue: 'traitBrushTechnologyIdValue',
    transmitterSource: 'transmitter source',
    signupTitle: 'signupTitle',
    signupDescription: 'signupDescription',
    disclaimer: 'disclaimer',
    emailLegalText: 'emailLegalText',
    expiryHours: 'expiryHours',
    signupCtaText: 'signupCtaText',
    thankYouTitle: 'thankYouTitle',
    thankYouDescription: 'thankYouDescription',
    thankYouDisclaimer: 'thankYouDisclaimer',
    emailLimitExceedErrorText: 'emailLimitExceedErrorText',
    brushNotSelectedErrorMessage: 'brushNotSelectedErrorMessage',
    alreadyParticipated: 'ALREADY_PARTICIPATED',
    checkBoxText:"checkBoxText",
    formSurveyLabels:"formSurveyLabels",
    loader:'/static/images/loader.gif',
    newTopicTitle:'topicTitle', 
    subCopy:'subCopy',
    interstitalSourceIO2: 'NO iO2 Website L.P - Mixture - LTIMindtree',
    IO2Registered:'IO2Registered'
});

export const SpotlightContestConstants = Object.freeze({
    error: 'An error occured',
    failedToRegister: ': Sign up failed',
    title: Constants.title,
    contestTitle: 'contestTitle',
    signUpCallToActionLabel: 'signUpCallToActionLabel',
    emailLimitExceedLabel: 'emailLimitExceedLabel',
    invalidLocaleLabel: 'invalidLocaleLabel',
    serviceUnavailableLabel: 'serviceUnavailableLabel',
    formFields: 'formFields',
    classNames: Constants.classNames,
    anchorId: Constants.anchorId,
    backgroundAsset: Constants.backgroundAsset,
    mobileBackgroundAsset: Constants.mobileBackgroundAsset,
    oralBLogoAsset: 'oralBLogo',
    backToOralbLink: 'backToOralbLink',
    ioLogoAsset: 'ioLogo',
    formOptinInputText: 'formOptinInputText',
    formOwnETBInputText: 'formOwnETBInputText',
    formLegalText: 'formLegalText',
    socialLinks: 'socialLinks',
    redirectDestination: 'redirectDestination',
    copyrightLabel: 'copyrightLabel',
    thankYouTitleLabel: 'thankYouTitleLabel',
    thankYouBackgroundAsset: 'thankYouBackgroundAsset',
    thankYouDescriptionLabel: 'thankYouDescriptionLabel',
    thankYouCallToAction: 'thankYouCallToAction',
    thankYouCallToActionLink: 'thankYouCallToActionLink',
    thankYouCallToActionIconName: 'thankYouCallToActionIconName',
    numberOfErrorsLabel: 'numberOfErrorsLabel',
    submit: Constants.submit,
    close: Constants.close,
    GcsURL: '/registration?access_token=',
    gcsCampaignParam: 'campaignId',
    gcsLocaleParam: 'locale',
    contentType: 'Content-Type',
    json: Constants.json,
    accessToken: 'access_token',
    system: 'GCS',
    chevronDown: Constants.thinChevronDown,
    modalLabelFirst: 'modalLabelFirst',
    modalLabelSecond: 'modalLabelSecond',
    addressTypeCode: 'addressTypeCode',
    alternateThankYouTitleLabel: 'alternateThankYouTitleLabel',
    alternateThankYouDescriptionLabel: 'alternateThankYouDescriptionLabel',
    continueReading: 'Continue reading',
});

export const SpotlightVideoPlayerConstants = Object.freeze({
    pgone: 'pgone',
    cloudinaryVideoPlayer: 'cloudinary-video-player',
    cloudinaryCore: 'cloudinary-core',
    inherit: 'inherit',
    never: 'never',
    sourceTypes: ['hls', 'mp4', 'ogg', 'webm'],
});

export const SpotlightStickyBarConstants = Object.freeze({
    stickyBarClassName: 'ob-SpotlightStickyBar',
    scrollTo: 'scrollTo',
    callToActionLink: Constants.callToActionLink,
    logoLink: 'logoLink',
    logo: 'logo',
    name: Constants.name,
    title: Constants.title,
    medium: Constants.medium,
    SignUp: 'Sign up',
    callToActionLabel: Constants.callToActionLabel,
    CallToActionLabel: 'CallToActionLabel',
});

export const SpotlightSliderConstants = Object.freeze({
    scrollerContainer: 'scroller-container ',
    classNames: Constants.classNames,
    continueReadingLabel: 'continueReadingLabel',
    main: 'main',
});

export const SpotlightPreorderConstants = Object.freeze({
    body: Constants.body,
    title: Constants.title,
    anchorId: Constants.anchorId,
    classNames: Constants.classNames,
    backgroundAsset: Constants.backgroundAsset,
    preOrderButtonLabel: 'preOrderButtonLabel',
    preOrderCallToAction: 'preOrderCallToAction',
    mobileBackgroundAsset: 'mobileBackgroundAsset',
});

export const SpotlightExperienceConstants = Object.freeze({
    description: Constants.description,
    title: Constants.title,
    anchorId: Constants.anchorId,
    classNames: Constants.classNames,
    backgroundAsset: Constants.backgroundAsset,
    callToActionLabel: 'callToActionLabel',
    callToActionLink: 'callToActionLink',
    experienceTitle: 'experienceTitle',
    experienceFeatures: 'experienceFeatures',
    closeLabel: 'closeLabel',
    chevronDown: Constants.chevronDown,
    countrySelectLabel: 'countrySelectLabel',
    countrySelectList: 'countrySelectList'
});

export const SpotlightExperienceProductConstants = Object.freeze({
    itemType: Constants.itemType,
    ProductName: 'ProductName',
    ratingSummary: 'inline_rating',
    button: Constants.button,
});


export const SpotlightContentVideoConstants = Object.freeze({
    introText: 'introText',
    introLogo: 'introLogo',
    title: Constants.title,
    titleLevel: Constants.titleLevel,
    defaultRight:"-720px",
    defaultBottom:"-170px",
    displaynone: "none",
    nextFrameOnVideoEnd: 'nextFrameOnVideoEnd',
    introDurationSecond: 'introDurationSecond',
    playButtonColor: 'playButtonColor',
    white: Constants.white,
    mobileFullSize: 'mobileFullSize',
    playLabel: 'playLabel',
    pauseLabel: 'pauseLabel',
    resetLabel: 'resetLabel',
    paused: 'paused',
    played: 'played',
    openTranscriptLabel: 'openTranscriptLabel',
    closeTranscriptLabel: 'closeTranscriptLabel',
    content: Constants.content,
    classNames: Constants.classNames,
    textContainerHasPadding: 'textContainerHasPadding',
    hide: 'hide',
    auto: 'auto',
    none: 'none',
    description: Constants.description,
    VideoId: 'VideoId',
    spotlightPause: 'spotlightPause',
    spotlightPlay: 'spotlightPlay',
    spotlightReplay: 'spotlightReplay',
    spotlightTranscript: 'spotlightTranscript',
    close: Constants.close,
});

export const SpotlightContentBlockConstants = Object.freeze({
    devicesConfigurations: 'devicesConfigurations',
    textAlign: 'textAlign',
    empty: Constants.empty,
    textAlignment: 'textAlignment',
    textContainerVerticalAlignment: 'textContainerVerticalAlignment',
    textContainerOffset: 'textContainerOffset',
    textContainerMarginRight: 'textContainerMarginRight',
    textContainerMarginLeft: 'textContainerMarginLeft',
    mainAsset: Constants.mainAsset,
    mainAssetHorizontalAlignment: 'mainAssetHorizontalAlignment',
    mainAssetMaxWidth: 'mainAssetMaxWidth',
    mainAssetMarginTop: 'mainAssetMarginTop',
    mainAssetMarginRight: 'mainAssetMarginRight',
    mainAssetOffsetBottom: 'mainAssetOffsetBottom',
    mainAssetOffsetLeft: 'mainAssetOffsetLeft',
    secondaryAsset: 'secondaryAsset',
    additionalAssetList: 'additionalAssetList',
    backgroundAsset: Constants.backgroundAsset,
    mainAssetScale: 'mainAssetScale',
    textFadeIn: 'textFadeIn',
    refreshImageOnFrameChange: 'refreshImageOnFrameChange',
    content: Constants.content,
    classNames: Constants.classNames,
    whiteText: Constants.whiteText,
    hasLink: 'hasLink',
    title: Constants.title,
    titleLevel: Constants.titleLevel,
    textImage: 'textImage',
    textImageMobile: 'textImageMobile',
    textImagePosition: 'textImagePosition',
    top: Constants.top,
    textContainerBackgroundColor: 'textContainerBackgroundColor',
    textContainerHasPadding: 'textContainerHasPadding',
    surtitle: 'surtitle',
    textContent: 'textContent',
    bottom: 'bottom',
    callToActionLink: Constants.callToActionLink,
    white: Constants.white,
    chevronRight: Constants.chevronRight,
    blue: Constants.blue,
    callToActionLabel: Constants.callToActionLabel,
});

export const SkipNavigationBlockConstants = Object.freeze({
    label: 'label',
    targetID: 'targetID',
    roleMain: '[role="main"]',
});

export const SideBySideContainerConstants = Object.freeze({
    anchorId: Constants.anchorId,
    name: Constants.name,
    left: 'left',
    right: 'right',
    sideBySide: 'side-by-side-',
    hasDivider: 'hasDivider',
    flipOnMobile: 'flipOnMobile',
    disclaimer: Constants.disclaimer,
    title: Constants.title,
    titleLevel: Constants.titleLevel,
    description: Constants.description,
    blocks: Constants.blocks,
});

export const ShowcaseBlockConstants = Object.freeze({
    classNames: Constants.classNames,
    title: Constants.title,
    description: Constants.description,
    sideImage: 'sideImage',
});

export const SeriesSelectorConstants = Object.freeze({
    master: 'master_',
    media: '(min-width: 768px)',
    dpr: 'f_auto,q_auto,w_398',
    empty: Constants.empty,
    ratingSummary: 'inline_rating',
    reviews: 'reviews',
    io: 'io',
    productRating: 'rating'
});

export const ProductOverviewConstant = Object.freeze({
    link: 'Link'
});

export const SingleProductRecommenderConstant = Object.freeze({
    title: 'title'
});

export const MultipleProductRecommenderConstant = Object.freeze({
    title: 'title'
});

export const SeriesTabsConstants = Object.freeze({
    button: Constants.button,
    true: 'true',
    false: 'false',
    chevronDown: Constants.chevronDown,
    buttonRole: 'button',
    page: 'page'
});
export const BuySeriesConstants = Object.freeze({
    chevronRight: Constants.chevronRight,
});

export const SeriesComparisonItemConstants = Object.freeze({
    itemType: Constants.itemType,
    ProductName: 'ProductName',
    ratingSummary: 'inline_rating',
    button: Constants.button,
    comingSoonText: 'comingSoonText',
    commingSoonItem: 'coparison-item',
    productRating: 'rating',
    learnMoreText: 'Mer information',
});

export const SeeMoreButtonConstants = Object.freeze({
    blue: Constants.blue,
    chevronLeft: 'chevronLeft',
    chevronRight: Constants.chevronRight,
    button: Constants.button,
});

export const AlgoliaConstants = Object.freeze({
    errorMsg: 'Cannot create algolia search client: ALGOLIA_APP_ID or ALGOLIA_APP_SEARCH_KEY envs are empty',
    crawlerTriggeredMsg:'Algolia crawler triggered!!'
});

export const algoliaReducerConstants = Object.freeze({
    SearchClicked : 'SearchClicked',
    queryParm : 'queryParm',
    searchStaticContent : 'searchStaticContent',
    hitsResponse : 'hitsResponse',
    filerClicked : 'filerClicked',
    pageNoClicked : 'pageNoClicked',
    showAutocomplete : 'showAutocomplete',
    searchValue : 'searchValue',
    isSpinning : 'isSpinning',
    totalPageNumber : 'totalPageNumber',
    filterItems : 'filterItems',
    nbHits : 'nbHits',
    nbPages : 'nbPages'
});

export const SearchBarConstants = Object.freeze({
    text: Constants.text,
    submit: Constants.submit,
    searchText: 'searchText',
    search: 'search',
    list: 'list',
    maximumAutocompleteResults: 10,
    searchTitle: 'searchTitle',
    common_search : 'COMMOM SEARCH',
    SearchClicked: 'SearchClicked',
    queryParm: 'queryParm',
    searchStaticContent: 'searchStaticContent',
    hitsResponse: 'hitsResponse',
    filerClicked: 'filerClicked',
    SearchSuggestions: 'SearchSuggestions',
    pageNoClicked: 'pageNoClicked',
    showAutocomplete: 'showAutocomplete',
    searchValue:'searchValue',
    isSpinning:'isSpinning',
    eventCategory: 'event_informational_action',
    eventAction: 'event_search',
    close:'close',
});

export const RotationConstants = Object.freeze({
    mouseup: 'mouseup',
    grabbing: 'grabbing',
    number: 'number',
    grab: 'grab',
    match: 'TEXTAREA|INPUT|SELECT',
    touch: 'touch',
});

export const RichTextConstants = Object.freeze({
    text: Constants.text,
    classNames: Constants.classNames,
    anchorId: Constants.anchorId,
});

export const ResponsiveImageConstants = Object.freeze({
    tiny: 'tiny',
    mobile: 'mobile',
    tablet: 'tablet',
    desktop: 'desktop',
});

export const RecentlyViewedProductsBlockConstants = Object.freeze({
    show: 'show',
    fetch: '/_next/static/products/',
    json: '.json',
    four: '4',
});

export const HierarchyUtilsConstants = Object.freeze({
    menu: 'MENU'
})

export const PromoConstants = Object.freeze({
    promo: 'promo ',
    classNames: Constants.classNames,
    whiteText: Constants.whiteText,
    subtitle: 'subtitle',
    description: Constants.description,
    retailerText: 'retailerText',
    mobileBackgroundAsset: Constants.mobileBackgroundAsset,
    backgroundAsset: Constants.backgroundAsset,
    titleLevel: Constants.titleLevel,
    title: Constants.title,
    retailerLogo: 'retailerLogo',
    callToActionLink: Constants.callToActionLink,
    callToActionLabel: Constants.callToActionLabel,
    horizontalAlignment: 'horizontalAlignment',
    large: Constants.large,
});

export const ProductSubNavConstants = Object.freeze({
    classNames: Constants.classNames,
    scroll: Constants.scroll,
    anchorClick: 'anchorClick',
    resize: Constants.resize,
    wheel: 'wheel',
    touchmove: Constants.touchmove,
    top: Constants.top,
    undefined: 'undefined',
    Collapsed: 'COLLAPSED',
    Collapsing: 'COLLAPSING',
    chevronDown: Constants.chevronDown,
    chevronTop: Constants.chevronTop,
    medium: Constants.medium,
    blank: '_blank',
    links: 'links',
    h1: Constants.h1,
    h2: Constants.h2,
    small: Constants.small,
    mediumLong: Constants.mediumLong,
    initialLoad: 'initialLoad',
    prepurchase: 'prepurchase'
});

export const ProductOverlayConstants = Object.freeze({
    closeOverlayLabel: 'closeOverlayLabel',
    empty: Constants.empty,
    classNames: Constants.classNames,
    keydown: Constants.keydown,
    noScroll: 'noScroll',
    scroll: Constants.scroll,
    isActive: 'is-active',
    querySelectorAll: 'button:not([disabled]), a[href]:not([disabled])',
    Tab: Constants.Tab,
    Escape: Constants.Escape,
    close: Constants.close,
    priceSpiderSource: '//cdn.pricespider.com/1/lib/ps-widget.js',
    priceSpiderConsoleLogMessageError: 'pricespider script not loaded',
    buyNowLiteErrorLabel: 'buyNowLiteErrorLabel',
    activateBuyNowLite: 'activateBuyNowLite'
});

export const ProductHighlightsConstants = Object.freeze({
    isActive: 'is-active',
    empty: Constants.empty,
    minus6: 'minus6',
    blue: Constants.blue,
    button: Constants.button,
    plus: 'plus',
    seeLess: 'SEE LESS',
    seeMore: 'SEE MORE',
    highlights: ' highlights',
    undefinedStr: 'undefined',
    iconArrowRight: 'chevronRight',
    arrowDown: 'ArrowDown',
    learnMore: 'Learn More',
    whyOralBHash: '#wiesooralb',
    innerWidth_4600: 4600,
    innerWidth_3000: 3000,
    innerWidth_2300: 2300,
    innerWidth_2000: 2000,
    innerWidth_1900: 1900,
    innerWidth_1700: 1700,
    innerWidth_1500: 1500,
    innerWidth_1200: 1200,
    innerWidth_380: 380,
    offset_10000: 10000,
    offset_7100: 7100,
    offset_5600: 5600,
    offset_5100: 5100,
    offset_4800: 4800,
    offset_4500: 4500,
    offset_4200: 4200,
    offset_3700: 3700,
    offset_5000: 5000,
    productRating: 'rating'
});

export const ProductGridBlockConstants = Object.freeze({
    title: Constants.title,
    titleLevel: Constants.titleLevel,
    one: '1',
    two: '2',
    classNames: Constants.classNames,
    anchorId: Constants.anchorId,
    eyebrow: Constants.eyebrow,
    body: Constants.body,
    disclaimer: Constants.disclaimer,
    seeMoreLabel: 'seeMoreLabel',
    seeLessLabel: 'seeLessLabel',
    maxNumberInlineItems: 'maxNumberInlineItems',
    whiteText: Constants.whiteText,
    radialBackground: 'radialBackground',
    background: 'background',
    removeLazyLoad: 'removeLazyLoad',
});

export const PageNavigationConstants = Object.freeze({
    classNames: Constants.classNames,
    pageNavigation: 'page-navigation--underlined',
    placeholderTitleLabel: 'placeholderTitleLabel',
    smooth: Constants.smooth,
    empty: Constants.empty,
    chevronDown: Constants.chevronDown,
    blocks: Constants.blocks,
});

export const OnePageScrollConstants = Object.freeze({
    scrollDisabled: 'scroll--disabled',
    onePageScrollWrapper: 'one-page-scroll-wrapper',
    easeInOut: 'ease-in-out',
    resize: Constants.resize,
    hashChange: Constants.hashChange,
    touchmove: Constants.touchmove,
    keydown: Constants.keydown,
    container: 'container_',
    polite: 'polite',
    smooth: Constants.smooth,
    thinChevronDown: 'thinChevronDown',
    querySelector: '[data-isactiveblock="true"]',
    scrollDown: 'Scroll down'
});

export const BazaarVoiceConstants = Object.freeze({
    masterProductIdPrefix: 'master_',
    masterProductNamePrefix: 'master_product_'
});

export const ArticleShareConstants = Object.freeze({
    facebookAlt: 'Share on Facebook',
    twitterAlt: 'Share on Twitter',
    shareTitle: 'Share',
    faceBookIcon: '/static/images/facebook.png',
    twitterIcon: '/static/images/twitter.png',
    button: 'button'
});

export const PypestreamChatConstants = Object.freeze({
    persistentChatTriggered: 'persistentChatTriggered_',
});

export const PressQuoteBlockConstants = Object.freeze({
    pressQuotes: 'pressQuotes',
    pressLogo: 'pressLogo',
    quote: 'quote',
    starRating: 'reviewStars',
    animationGifAsset: 'animationGifAsset',
    callToActionLabel: 'ctaLabel',
    callToActionLink: 'ctaLink',
    iconArrowLeft: 'chevronLeft',
    iconArrowRight: 'chevronRight',
    previousLabel: 'previousLabel',
    nextLabel: 'nextLabel',
    transparentWithBorders: 'Transparent with Borders',
    ariaLabel:'ariaLabel'
});

export const SearchBlockConstants = Object.freeze({
    queryHashName: 'query',
    queryHashEqual: '=',
    faqStylePageLinks: 'Page Links',
    faqStyleDropdown: 'Dropdown',
    allResultsCategory: 'All',
    pageCategory: 'Page',
    collectionCategory: 'Collection',
    productCategory: 'Product',
    articleCategory: 'Article',
    searchText: 'searchText',
    faqCategory: 'FAQ'
});

export const CountryCode = Object.freeze({
    Belgique: 'BEL',
    France:'FRA',
    Deutschland: 'DEU',
    Schweiz:'CHE',
    'Österreich':'AUT',
    Nederland:'NLD',
    Belgie:'BEL',
    'United Kingdom':'GBR',
    'Ireland':'IRL'
});

export const SearchServiceConstants = Object.freeze({
    contentType: 'Content-Type',
    json: Constants.json,
    swiftypeBaseUrl: 'https://api.swiftype.com/api/v1',
    swiftypePath: '/public/engines/search',
    swiftypeAutocompleteUrl: 'https://search-api.swiftype.com/api/v1/public/engines/suggest.json'
});

export const JanrainConstants = Object.freeze({
    productListLocalStorageKeyPrefix: 'oralbProductList-',
    benefitListLocalStorageKeyPrefix: 'oralbBenefitPreferenceList-'
});

export const SvcApiConstants = Object.freeze({
    contentType: 'Content-Type',
    ciamInstanceId: 'CiamInstanceId',
    resendVerificationForm: 'resendVerificationForm',
    unknownAccessToken: 'unknown access token'
});

export const WarrantyExtensionConstants = Object.freeze({
    contentType: 'Content-Type',
    json: Constants.json,
    accessToken: 'access_token',
    oneCPId: 'oneCPId',
    UUID: 'UUID',
    programIdParam: 'programId=',
    categoryIdParam: 'categoryId=',
    localeParam: 'locale=',
    productId: 0, // based on the API team request while doing warenty extension rewamp
    internGroupValue: 'OC',
    addressTypeCode: 'INVOICE_ADDRESS',
    warrantyExtensionPath: 'warrantyextension',
    getConsumerPath: 'consumer/getConsumer',
    getProductsByTypeCodePath: 'products/productByGas',
    getCampaignInfoMaster: 'program',
    getproductListBySeries: 'products/productListBySeries',
    gasPrimaryProductDetailKey: 'gasPrimaryProductDetail',
    gasSecondaryProductDetailKey: 'gasSecondaryProductDetail',
    primaryProductIndex: 0,
    comboProductIndex: 1,
    janrinProductIdDelimiter: '_',
    janrinProfileOldestProductIndex: 0,
    code: 'Code',
    COUPON_CODE_ENDPOINT: 'voucher',
    io10Input: 'iO 10',
    termsAndConditionsOptText: "Terms and conditions",
    ciamInstanceId: "ciamInstanceId",
    channelId: "channelId",
    Authorization: "Authorization",
    ciamInstanceIdVal: "EU" 
});

export const FaqDetailsBlockConstants = Object.freeze({
    classNames: 'classNames',
    anchorId: 'anchorId',
    baseClass: 'ob-faq-details-block '
});

export const CarouselBlockConstants = Object.freeze({
    productCard : 'productCard',
    cloudinaryVideo:'cloudinaryVideo',
    chevronRight: Constants.chevronRight,
    articleProductCard: 'articleProductCard',
    htmlBlock: 'htmlBlock',
    contentBlock: 'contentBlock',
    leftArrow: 'vänster pil',
    rightArrow : 'höger pil' ,
    carouselTabItems: 'carouselTabItem',
    miniContentBlock:'blockMiniContentBlock'
});

export const IndexationUrlFactoryConstants = Object.freeze( {
    swiftypeBaseUrl: 'https://api.swiftype.com/api/v1/engines/',
    swiftypeDocumentTypes: '/document_types/',
    swiftypeAuth: '?auth_token=',
    json: '.json',
    specificSwiftypeDocument: '/documents/',
    generalSwiftypeDocument: '/documents'
});

export const RichTextLinkBlockConstants = Object.freeze({
    classNames: 'classNames',
    image: 'image',
    url: 'url',
    linkType: 'type',
    title: 'title',
    className: 'className',
    GAclassName: 'linkClassName',
    dataActionDetail: 'dataActionDetail'
});

export const BuyNowLiteConstants = Object.freeze({
    accessControlAllowOrigin: 'Access-Control-Allow-Origin',
    xFunctionKey: 'x-functions-key',
    passKey: 'passkey',
    ocpApimSubscriptionKey: 'Ocp-Apim-Subscription-Key',
    image: 'image',
    url: 'url',
    linkType: 'type',
    title: 'title',
});

export const ImageContenfullConstants = Object.freeze({
    defaultImageWindWidth: 450,
    defaultWidth: 400,
    defaultDesktopWidth:768,
    dpr_1440: 'f=center&q=100&w=1440',
    product: 'product',
    dpr_365: 'f=center&q=100&w=365',
    dpr_376: 'f=center&q=100&w=376',
    dpr_426: 'f=center&q=100&w=426',
    dpr_768: 'f=center&q=100&w=768',
    dpr_992: 'f=center&q=100&w=992',
    dpr_1200: 'f=center&q=100&w=1200',
    media_1200: '(min-width: 1200px)',
    media_992: '(min-width: 992px)',
    media_768: '(min-width: 768px)',
    media_376: '(min-width: 376px)'

});

export const ContentSeriesSelectorConstants = Object.freeze({
    master: 'master_',
    media: '(min-width: 768px)',
    dpr: 'f=center&q=100&w=398',
    empty: Constants.empty,
    ratingSummary: 'inline_rating',
    reviews: 'reviews',
});

export const FileTypeConstants = Object.freeze({
    mp4: 'audio/mp4',
    mp3: 'audio/mp3',
    m4a: 'audio/mpeg'
});

export const miniContentBlockConstants = Object.freeze( {
    blueCtaColorValue: 'blue',
    whiteCtaColorValue: 'white',
    blueCtaColor: '#002D72',
    whiteCtaColor: '#fff'
})

export const dentistContacts = Object.freeze({
    https: 'https://',
    http: 'http://',
    www: 'www.',
    allDentist: 'All Individuals / Practices',
    individuals: 'Individuals',
    practices: 'Practices'
});

export const calendarBlockConstants = Object.freeze( {
    Google: 'Google',
    Yahoo: 'Yahoo',
    Outlook: 'Outlook',
    Other: 'Other',
})
export const StartGuideBlockConstants = Object.freeze({
    name: 'name',
    className: 'className',
    backgroundAsset: 'backgroundAsset',
    mobileBackgroundAsset: 'mobileBackgroundAsset',
    textAlignment:'textAlignment',
    title: 'title',
    subTitle: 'subTitle',
    description: 'description',
    descriptionIcon: 'descriptionIcon',
    topicList: 'topicList',
    cards: 'cards',
    topicHeader: 'topicHeader',
    carouselCards: 'carouselCards',
    ctaInformation: 'ctaInformation',
    titleImage: 'titleImage',
    scrollContent: 'scrollContent',
    animationAsset: 'animationAsset',
    mobileAnimationAsset: 'mobileAnimationAsset',
    additionalAssetList: 'additionalAssetList',
    remainderCalender: 'remainderCalender',
    video: 'video',
    isAnimationCards: 'isAnimationCards',
    popupContent: 'popupContent',
    anchorId: 'anchorId',
    id: 'id',
    isVerticalCarousel: 'isVerticalCarousel',
    carouselBackground: 'carouselBackground',
    carouselForeground: 'carouselForeground',
    carouselItems: 'carouselItems',
    cycleSettingsButton: 'Press to cycle settings',
    right: 'Right',
    left: 'Left',
    bottom: 'bottom',
    hamburgerIcon: 'hamburgerIcon',
    closeIcon: 'closeIcon',
    classData: 'classData',
    desktopButtonAsset: 'desktopButtonAsset',
    mobileButtonAsset: 'mobileButtonAsset',
    Google: 'Google',
    Yahoo: 'Yahoo',
    Outlook: 'Outlook',
    Other: 'Other',
    longText: 'longText',
    buttonText: 'buttonText',
    isNewSignUp:'isNewSignUp',
    formLabels:'formLabels',
    anchorId: Constants.anchorId,
    thankYouTitle: 'thankYouTitle',
    thankYouDescription: 'thankYouDescription',
    thankYouDisclaimer: 'thankYouDisclaimer',
    modalLabelFirst: 'modalLabelFirst',
    modalLabelSecond: 'modalLabelSecond',
    legalText: 'legalText',
    welcomeText: 'welcomeText',
    gaEventTitle:'gaEventTitle'
});
export const StartGuideShuffleBlockConstant = Object.freeze({
    popupContent: 'popupContent',
    ctaInformation: 'ctaInformation',
})
